import { CANONICAL_BASE_URL } from "~/lib/constants";
import { required } from "~/lib/required";

type GetUrlsReq = Readonly<{ path: string; request: Request }>;

export type Urls = Readonly<{
  base: string;
  canonical: string;
  envDependent: string;
}>;

export function getUrls({ path, request }: GetUrlsReq): Urls {
  const base = getBaseUrl(request);
  return {
    base,
    canonical: getCanonicalUrl(path),
    envDependent: `${base}/${path}`,
  };
}

export function getCanonicalUrl(path: string) {
  return `${CANONICAL_BASE_URL}/${path}`;
}

export function isLocal(request: Request) {
  return local(getHost(request));
}

function getBaseUrl(request: Request) {
  const host = getHost(request);
  const protocol = `http${local(host) ? "" : "s"}`;
  return `${protocol}://${host}`;
}

function getHost(request: Request) {
  const forwardedHost = request.headers.get("X-Forwarded-Host");
  return required(forwardedHost ?? request.headers.get("host"), "host");
}

function local(host: string) {
  return host.includes("localhost") || host.includes("127.0.0.1");
}
